
// 绑定菜单和角色文档地址：http://219.128.52.2:18765/doc.html?tdsourcetag=s_pctim_aiomsg#/haolv-consumer/t-bd-role-menu-controller/bindingRoleMenuUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_roleMenu_bindingRoleMenu = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/roleMenu/bindingRoleMenu',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_roleMenu_bindingRoleMenu;