import Q from "q";
// 角色列表
import consumer_role_list from "@/lib/data-service/haolv-default/consumer_role_list.js";
// 角色权限菜单
import consumer_menu_getCompanyMenu from "@/lib/data-service/haolv-default/consumer_menu_getCompanyMenu.js";
// 添加修改角色
import consumer_role_addOrUpdate from "@/lib/data-service/haolv-default/consumer_role_addOrUpdate.js";
// 删除角色
import consumer_role_delete from "@/lib/data-service/haolv-default/consumer_role_delete.js";
// 绑定角色菜单
import consumer_roleMenu_bindingRoleMenu from "@/lib/data-service/haolv-default/consumer_roleMenu_bindingRoleMenu.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      loading: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        roleName: "",
      },
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      list: [],
      menuList: [],
      checkedList: [],
      addVisible: false,
      dialogTitle: "",
      roleForm: {
        roleName: "",
        remark: "",
      },
      roleRules: {
        roleName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        remark: [],
      },

      authorityForm: {
        roleId: "",
        menuIds: "",
      },
      defaultProps: {
        children: "menus",
        label: "name",
      },
      loadingInstance: false,
    };
  },
  methods: {
    req_list(params, type) {
      const _this = this;
      _this.loading = true;
      _this.list = [];
      Q.when()
        .then(function() {
          return consumer_role_list(params);
        })
        .then(function(res) {
          _this.list = res.datas.list;
          _this.pager.total = res.datas.totalCount;
          if (type) document.documentElement.scrollTop = 0;
          _this.loading = false;
        });
    },
    req_menulist(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return consumer_menu_getCompanyMenu(params);
        })
        .then(function(res) {
          const datas = res.datas;
          const defaultMuneIdList = datas.commonMenu.map((item) => {
            _this.checkedList.push(item.id);
            return item.id
          });
          let menuList = datas.allMenu;

          let setDisabledToTrue = (list) => {
            list.forEach((item) => {
              if (defaultMuneIdList.includes(item.id)) {
                item.disabled = true;
                  if (item.menus && item.menus.length > 0) {
                    setDisabledToTrue(item.menus)
                  }
              }
            })
          };

          setDisabledToTrue(menuList);

          // let menuList = JSON.parse(JSON.stringify(datas.allMenu))
          // function setDisabledToTrue(list) {
          //   list.forEach(value => {
          //     value.disabled = true
          //     if (value.menus && value.menus.length > 0) {
          //       setDisabledToTrue(value.menus)
          //     }
          //   })
          // }


          // 禁用勾选行程预定和办公
          // menuList.forEach(value => {
          //   if (['2', '3'].indexOf(value.id) > -1) {
          //     value.disabled = true
          //     if (value.menus && value.menus.length > 0) {
          //       setDisabledToTrue(value.menus)
          //     }
          //   }
          // })


          _this.menuList = menuList
          if (params)
            _this.checkedList = _this.return_checked_mune(_this.menuList);
          _this.addVisible = true;
        });
    },
    req_add(params) {
      return Q.when().then(function() {
        return consumer_role_addOrUpdate(params);
      });
    },
    req_bind_menu(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return consumer_roleMenu_bindingRoleMenu(params);
        })
        .then(function(res) {
          const dialogTitle = _this.dialogTitle;
          _this.handleClose();

          _this.loadingInstance = false;
          _this.$message.success(`${dialogTitle}成功`);
          _this.req_list(_this.form);
        }).catch(() => {
          _this.loadingInstance = false;
        });
    },
    req_delete(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return consumer_role_delete(params);
        })
        .then(function(res) {
          _this.$message.success("删除成功");
          _this.req_list(_this.form, "delete");
        });
    },
    return_checked_mune(list) {
      const _this = this;
      let menuList = [];
      list.forEach(function(item) {
        if (item.menus && item.menus.length) {
          menuList = [
            ...menuList,
            ..._this.return_checked_mune(item.menus),
          ];
        } else {
          if (item.roleId === _this.authorityForm.roleId)
            menuList.push(item.id);
        }
      });
      return menuList;
    },
    search() {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    change_search (val) {
      if (!val) {
        this.search();
      }
    },
    add_role(type, row) {
      if (row) {
        if (type === "authority") {
          this.dialogTitle = "权限分配";
        } else if (type === "change") {
          this.dialogTitle = "编辑角色";
        }
        this.authorityForm.roleId = row.id;
        const form = this.roleForm;
        form.id = row.id;
        form.roleName = row.roleName;
        form.remark = row.remark;

        this.req_menulist({ roleId: row.id });
      } else {
        this.dialogTitle = "添加角色";
        this.req_menulist();
      }
    },
    handleClose() {
      this.addVisible = false;
      this.checkedList = [];
      this.dialogTitle = "";
      this.$refs.roleForm.clearValidate();
      this.roleForm = {
        roleName: "",
        remark: "",
      };
      this.roleForm.id = "";
      this.authorityForm = {
        roleId: "",
        menuIds: "",
      };
    },
    commit_role() {
      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          const newAuthority = this.$refs.tree.getCheckedNodes(false, true);
          this.authorityForm.menuIds = newAuthority.map((item) => {
            return item.id
          }).toString();
          const _this = this;
          _this.loadingInstance = true;
          Q.when()
            .then(function() {
              return _this.req_add(_this.roleForm);
            })
            .then(function(res) {
              if (res.datas) _this.authorityForm.roleId = res.datas;
              _this.req_bind_menu(_this.authorityForm);
            }).catch((err) => {
              _this.loadingInstance = false;
            });
        }
      });
    },
    delete_role(id) {
      this.$confirm("确认删除此角色?", "操作确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.req_delete({ id });
        })
        .catch(() => {});
    },
    distribution_role() {},
    commit_authority() {
      const newAuthority = this.$refs.tree.getCheckedNodes();
      this.authorityForm.menuIds = this.check_authority(newAuthority).join();
    },
    check_authority(list) {
      const _this = this;
      let menuIds = [];
      list.forEach(function(item) {
        if (item.menus && item.menus.length) {
          const arr = _this.check_authority(item.menus);
          menuIds = [...menuIds, ...arr];
        }
        const find = menuIds.indexOf(item.id);
        if (find === -1) menuIds.push(item.id);
      });
      return menuIds;
    },
    change_currentPage(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form, "currentPage");
    },
    change_pageSize(pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      this.req_list(this.form, pageSize);
    },
  },
  mounted() {
    this.req_list(this.form);
  },
  activated() {},
  deactivated() {},
};
